import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import GraphicsCanvas from '../components/GraphicsCanvas';
import FullScreenCanvas from '../components/FullScreenCanvas';

function Overlay({channel}) {
    const { id } = useParams();
    const channelName = `LN-Ziggo-${id}`;

    return (
        <div className="App">
            {channel === "full-screen" && <FullScreenCanvas channelName={channelName} />}
            {channel === "overlay" && <GraphicsCanvas channelName={channelName} />}
            {channel === "preview" && <GraphicsCanvas channelName={`${channelName}-preview`} />}
        </div>
    );
}

export default Overlay;
import { gsap } from "gsap";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {LogoZS, LogoZSDocu, LogoZSGolf, LogoZSRacing, LogoZSSelect, LogoZSVoetbal} from './Logos'

const SwitchLogos = ({name, posX, posY, width}) => {
    if(name === "ZSDocu") {
        return <LogoZSDocu className="fill-white" posX={posX} posY={posY} width={width} />
    } else if (name === "ZSGolf") {
        return <LogoZSGolf className="fill-white" posX={posX} posY={posY} width={width} />
    } else if (name === "ZSRacing") {
        return <LogoZSRacing className="fill-white" posX={posX} posY={posY} width={width} />
    } else if (name === "ZSSelect") {
        return <LogoZSSelect className="fill-white" posX={posX} posY={posY} width={width} />
    } else if (name === "ZSVoetbal") {
        return <LogoZSVoetbal className="fill-white" posX={posX} posY={posY} width={width} />
    } else {
        return <LogoZS className="fill-white" posX={posX} posY={posY} width={width} />
    }
}

const Reference = ({ show, properties }) => {
    const graphicRef = useRef(null);
    const timelineRef = useRef(null);
    const contextRef = useRef(null);
    const [currentLabel, setCurrentLabel] = useState('hiddenIn');
    
    /**
     * Create the timeline
     */
    useLayoutEffect(() => {
        timelineRef.current = gsap.timeline({
            paused: true,
            ease: 'power2.inOut',
            duration: 1
        });

        // Set the context for this timeline
        contextRef.current = gsap.context(() => {

            // Default starting states */
            gsap.set("#ReferenceMatte", { x: "-50%" });
            gsap.set(["#ReferenceLogoBackground", "#ReferenceLogoBackgroundMatte"], { transformOrigin: 'center center'});

            // In- and Out- animations
            timelineRef.current
                .addLabel('hiddenIn')
                .to("#ReferenceMatte", {x: "+=100%", duration: 1})
                .to("#ReferenceLogoBackgroundMatte", { rotate: 120, duration: 1, y: "-=520" }, "<")
                .addLabel('visible')
                .to("#ReferenceLogoBackgroundMatte", { rotate: 180, x: "+=300", y: "+=300" })
                .to("#ReferenceMatte", {x: "+=100%"}, ">-0.2")
                .addLabel('hiddenOut');
        }, graphicRef);

        // Clean up GSAP when component unmounts
        return () => {
            timelineRef.current.kill();
            contextRef.current.revert();
        };
    }, []);

    /**
     * Listen for events
     */
    useEffect(() => {
        if (show) {
            if (currentLabel === 'hiddenIn' || currentLabel === 'hiddenOut') {
                timelineRef.current.tweenFromTo('hiddenIn', 'visible');
                setCurrentLabel('visible');
            }
        } else {
            if (currentLabel === 'visible') {
                timelineRef.current.tweenFromTo('visible', 'hiddenOut');
                setCurrentLabel('hiddenOut');
            }
        }

    }, [show, currentLabel])

    return (
        <g ref={graphicRef}>
            <mask id="ReferenceMask">
                <rect id="ReferenceMatte" className="fill-white" x="0" y="540" width="1920" height="540" transform="skewX(-30)" /> 
            </mask>
            <mask id="BackgroundMask">
                <rect id="ReferenceBackgroundMatte" className="fill-white" x="1068" y="672" width="756" height="300" rx="16" />
            </mask>
            <mask id="ReferenceLogoBackgroundMask">
                <rect id="ReferenceLogoBackgroundMatte" className="fill-white" x="1550" y="1100" width="500" height="500" rx="30" />
            </mask>

            <g mask="url(#ReferenceMask)" id="Reference">
                <rect id="ReferenceBackground" className="fill-orange" x="1068" y="772" width="756" height="200" rx="16" />
                <g mask="url(#ReferenceLogoBackgroundMask)">
                    <rect id="ReferenceLogoBackground" className="fill-black" x="1068" y="772" width="756" height="200" rx="16" />
                </g>
                <text id="ReferenceTop" className="font-diodrum text-lg fill-white" transform="translate(1098.16 839.91)">{properties && properties.title_top}</text>
                <text id="ReferenceBottom" className="font-diodrum text-lg fill-white" transform="translate(1098.16 895.91)">{properties && properties.title_bottom}</text>
                <text id="ReferenceText" className="font-diodrum text-sm fill-black" transform="translate(1098.16 942.57)">
                    {properties && properties.subtext}
                </text>

                <g mask="url(#ReferenceBackgroundMask)">
                    <g id="ReferenceLogos" mask="url(#ReferenceLogoBackgroundMask)">
                        <SwitchLogos name={properties?.channel || ""} posX={1560} posY={835} width={220} />
                    </g>
                </g>
            </g>           
        </g>
    )
}

export default Reference
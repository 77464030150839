import React from 'react';
import { gsap } from "gsap";

const { useLayoutEffect, useRef } = React;

function Panel(delay) {
    const tl = useRef();

    useLayoutEffect((delay) => {
        tl.current = gsap
            .timeline({
                repeat: -1,
                yoyo: true,
                defaults: { ease: "ease" }
            })
            .to("#stop1", {
                stopOpacity: "0.2",
                duration: delay
            }, delay)
            .to("#stop2", {
                stopOpacity: "0.5",
                duration: delay
            }, delay)
    }, []);

    return (
        <>
            <defs>
                <radialGradient id="gradientCircularGray" cx="400" cy="400" r="500" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#0b0d10" stopOpacity="0" />
                    <stop id="stop1" offset="0.7" stopColor="#4c4e4e" stopOpacity="0.5" />
                    <stop id="stop2" offset="1" stopColor="#4c4e4e" />
                </radialGradient>
            </defs>
            <rect className="Panel" width="600" height="600" rx="50" transform="translate(-300 -300)" />
        </>
    );
}

export default Panel;

import React, { useState, useEffect } from 'react';

const FullScreenImage = ({ src, alt, show }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    if (show) {
      const image = new Image();
      image.src = src;

      image.onload = () => {
        setIsLoaded(true);
        setFadeIn(true);
      };
    } else {
      setFadeIn(false);
    }
  }, [src, show]);

  const imageStyle = {
    opacity: fadeIn ? 1 : 0,
    transition: 'opacity 0.5s ease'
  };

  return (
    <img src={src} alt={alt} style={imageStyle} />
  );
};

export default FullScreenImage;
import { gsap } from "gsap";
import Pusher from 'pusher-js';
import { useEffect, useLayoutEffect, useRef, useState } from "react";

const LineupPlayer = ({ shirtNumber, name, posX }) => {
    return (
        <g id="Player">
            <rect className="fill-dark" x={posX} y="873" width="50" height="50" />
            <text className="font-diodrum text-sm fill-white" transform={`translate(${posX + 25} 907)`} textAnchor="middle">{shirtNumber}</text>
            <text className="font-diodrum text-sm fill-white" transform={`translate(${posX + 75} 907)`}>{name}</text>
        </g>
    )
}

const LineupLine = ({ posY, players }) => {
    const length = players.length;
    const baseX = Math.floor(960 + length * -140);

    return (
        <g transform={`translate(0 ${posY})`}>
            <rect className="fill-black" x="220.15" y="872" width="1480" height="50" rx="8" />
            {players.map((player, index) => {
                let posX = baseX + index * 280
                return (
                    <LineupPlayer key={index} shirtNumber={player.shirtNumber} name={player.name} posX={posX} />
                )
            }
            )}
        </g>
    )
}

const LineupTicker = ({ show, channelName }) => {
    const graphicRef = useRef(null);
    const timelineRef = useRef(null);
    const contextRef = useRef(null);
    const [currentLabel, setCurrentLabel] = useState('hiddenIn');

    /**
     * Create the timeline
     */
    useLayoutEffect(() => {
        timelineRef.current = gsap.timeline({
            paused: true,
            ease: 'power2.inOut',
            duration: 1
        });
        // Set the context for this timeline
        contextRef.current = gsap.context(() => {

            // Default starting states */
            gsap.set("#LineupTicker", { autoAlpha: 0 })
            
            // In- and Out- animations
            timelineRef.current
                .addLabel('hiddenIn')
                .to("#LineupTicker", { autoAlpha: 1 })
                .addLabel('visible')
                .to("#LineupTicker", { autoAlpha: 0 })
                .addLabel('hiddenOut');
        }, graphicRef);

        // Clean up GSAP when component unmounts
        return () => {
            timelineRef.current.kill();
            contextRef.current.revert();
        };
    }, []);

    /**
     * Listen for events
     */
    useEffect(() => {
        if (show) {
            gsap.set('#Formation', {y: 0})

            if (currentLabel === 'hiddenIn' || currentLabel === 'hiddenOut') {
                timelineRef.current.tweenFromTo('hiddenIn', 'visible');
                setCurrentLabel('visible');
            }
        } else {
            if (currentLabel === 'visible') {
                timelineRef.current.tweenFromTo('visible', 'hiddenOut');
                setCurrentLabel('hiddenOut');
            }
        }

    }, [show, currentLabel]);

    /**
     * List for tick events
     */
    useEffect(() => {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
            cluster: 'eu'
        });

        // Subscribe to the channel
        const channel = pusher.subscribe(channelName);

        // Bind to channel websocket events
        channel.bind('Tick', function (data) {
            if(data.direction === 'up') {
                gsap.to('#Formation', {y: "-=95"})
            }
            if(data.direction === 'down') {
                gsap.to('#Formation', {y: "+=95"})
            }
            if(data.direction === 'reset') {
                gsap.to('#Formation', {y: 0})
            }
        })
    }, [])

    const lineup = {
        formation: "4-3-3",
        lineup: {
            goalkeeper: [
                { position: "GK", shirtNumber: 1, name: "John Doe" }
            ],
            defense: [
                { position: "RB", shirtNumber: 2, name: "Mark Smith" },
                { position: "CB", shirtNumber: 4, name: "David Johnson" },
                { position: "CB", shirtNumber: 5, name: "Michael Williams" },
                { position: "LB", shirtNumber: 3, name: "Robert Brown" },
            ],
            midfield: [
                { position: "CM", shirtNumber: 6, name: "Daniel Jones" },
                { position: "CM", shirtNumber: 8, name: "Christopher Wilson" },
                { position: "CM", shirtNumber: 10, name: "Matthew Taylor" },
            ],
            attack: [
                { position: "RW", shirtNumber: 7, name: "Andrew Anderson" },
                { position: "ST", shirtNumber: 9, name: "Thomas Martinez" },
                { position: "LW", shirtNumber: 11, name: "James Robinson" },
            ]
        }
    }

    return (
        <g ref={graphicRef}>
            <mask id="LineupMask">
                <rect className="fill-white" x="220.15" y="872" width="1480" height="50" rx="8"/>
            </mask>

            <g id="LineupTicker">
                <rect className="fill-orange" x="405.15" y="932" width="1110" height="40" rx="6" />
                <text className="font-diodrum text-sm fill-white" transform="translate(845.28 962.23)">SS LAZIO  | {lineup.formation}</text>

                <g mask="url(#LineupMask)">
                    <g id="Formation">
                        {Object.values(lineup.lineup).map((players, index) => (
                            <LineupLine key={index} posY={index * -100} players={players} />
                        ))}
                    </g>
                </g>
            </g>
        </g>
    )
}

export default LineupTicker
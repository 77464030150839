import { gsap } from "gsap";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import LogoLabel from "./LogoLabel";

const Results = ({ show, properties }) => {
    const graphicRef = useRef(null);
    const timelineRef = useRef(null);
    const contextRef = useRef(null);

    const [currentLabel, setCurrentLabel] = useState('hiddenIn');

    /**
     * Create the timeline
     */
    useLayoutEffect(() => {
        timelineRef.current = gsap.timeline({
            paused: true,
            ease: 'power2.inOut',
            duration: 1
        });
        // Set the context for this timeline
        contextRef.current = gsap.context(() => {

            // Default starting states */
            gsap.set("#Results", { autoAlpha: 0 });

            // In- and Out- animations
            timelineRef.current
                .addLabel('hiddenIn')
                .to("#Results", { autoAlpha: 1 })
                .to("#ResultsMaskOver", {height : 0})
                .addLabel('visible')
                .to("#Results", { autoAlpha: 0 })
                .addLabel('hiddenOut');
        }, graphicRef);

        // Clean up GSAP when component unmounts
        return () => {
            timelineRef.current.kill();
            contextRef.current.revert();
        };
    }, []);

    /**
     * Listen for events
     */
    useEffect(() => {
        if (show) {
            if (currentLabel === 'hiddenIn' || currentLabel === 'hiddenOut') {
                timelineRef.current.tweenFromTo('hiddenIn', 'visible');
                setCurrentLabel('visible');
            }
        } else {
            if (currentLabel === 'visible') {
                timelineRef.current.tweenFromTo('visible', 'hiddenOut');
                setCurrentLabel('hiddenOut');
            }
        }

    }, [show, currentLabel])

    return (
        <g ref={graphicRef}>
            <mask id="ProgramMask">
                <rect id="ProgramMaskMatte" className="fill-white" x="210" y="165" width="1500" height={properties && properties.rows.length * 70 - 8} rx="8"/>
            </mask>

            <mask id="ProgramTimeMask">
                <rect id="ProgramTimeMatte" className="fill-white" x="0" y="0" width="356" height="1080"/>
            </mask>

            <g id="Results">
                <text className="font-diodrum text-2xl fill-orange auto-alpha" transform="translate(520 124)">
                    {properties?.title || ""}
                </text>

                <g mask="url(#ProgramMask)">   
                    {properties && properties.rows && properties.rows.map((row, index) => (
                        <g key={index}>
                            <rect className="fill-black fill-opacity-65" x="210" y={index * 70 + 165} width="1500" height="62" rx="8"/>
                            <rect className="fill-orange" x="882" y={index * 70 + 165} width="156" height="62"/>
                            <text className="fill-white font-diodrum text-md" transform={`translate(862 ${index * 70 + 210})`} textAnchor="end">{row['homeTeam']}</text>
                            <text className="fill-white font-diodrum text-md" transform={`translate(1058 ${index * 70 + 210})`} textAnchor="start">{row['result']}</text>
                            <text className="fill-black font-diodrum text-md" transform={`translate(960 ${index * 70 + 210})`} textAnchor="middle">{row['awayTeam']}</text>
                        </g>
                    ))}
                </g>

                <rect id="ResultsMaskOver" className="fill-darkorange" x="210" y="165" width="1500" height={properties && properties.rows.length * 70 - 8} rx="8"/>

                <LogoLabel />
            </g>
        </g>
    )
}

export default Results
import PathWrapper from "../PathWrapper"

const IconTwitter = ({className, width , posX, posY}) => {
    return (
        <PathWrapper
            originalWidth={80}
            desiredWidth={width}
            posX={posX}
            posY={posY}
        >
            <path className={className} d="M60,11.39a24.68,24.68,0,0,1-7.07,1.94,12.36,12.36,0,0,0,5.41-6.81,24.61,24.61,0,0,1-7.81,3,12.32,12.32,0,0,0-21,11.23A34.92,34.92,0,0,1,4.18,7.87,12.32,12.32,0,0,0,8,24.31a12.26,12.26,0,0,1-5.58-1.55v.16A12.31,12.31,0,0,0,12.29,35a12.41,12.41,0,0,1-3.24.43,12.59,12.59,0,0,1-2.32-.21,12.31,12.31,0,0,0,11.5,8.54A24.75,24.75,0,0,1,2.94,49,23.7,23.7,0,0,1,0,48.85a34.79,34.79,0,0,0,18.87,5.53c22.64,0,35-18.75,35-35q0-.8,0-1.59A24.92,24.92,0,0,0,60,11.4Z"/>
        </PathWrapper>
    )
}

export default IconTwitter
const PathWrapper = ({children, originalWidth, desiredWidth, posX, posY}) => {
    const scale = desiredWidth / originalWidth;
    const transformScale = `scale(${scale})`;
    const transformPos = `translate(${posX}, ${posY})`;
    
    return (
        <g transform={transformPos}>
            <g transform={transformScale}>
                {children}
            </g>
        </g>
    )
}

export default PathWrapper
import PathWrapper from "../PathWrapper"

const LogoZSVoetbal = ({ className, width, posX, posY }) => {
    return (
        <PathWrapper
            originalWidth={465}
            desiredWidth={width}
            posX={posX}
            posY={posY}
        >
            <path className={className} d="M23.29,72.89c1.51,3.49,4.06,4.91,8.79,4.91H77.25a12.34,12.34,0,0,0-10.5-11.9H40.54L70.74,37a9,9,0,0,0,2.08-9.77c-1.64-3.57-4.37-5.09-9.16-5.09H26c.16,5,2.64,10.94,9.27,12h21.5L25,64A8.44,8.44,0,0,0,23.29,72.89Z" />
            <path className={className} d="M88,33V77.43A10.58,10.58,0,0,1,77.46,88h-55A10.58,10.58,0,0,1,11.9,77.44l0-54.92A10.6,10.6,0,0,1,22.46,11.94h55a10.55,10.55,0,0,1,4.11.83A12.14,12.14,0,0,1,85.79,16,6,6,0,0,0,96,11.72,6.21,6.21,0,0,0,94.2,7.49,21.81,21.81,0,0,0,86,1.71,22.18,22.18,0,0,0,77.46,0h-55A22.48,22.48,0,0,0,0,22.45V77.52A22.49,22.49,0,0,0,22.48,100H77.56A22.47,22.47,0,0,0,99.94,77.44V22.12C93.68,22.12,88,27.32,88,33Z" />
            <path className={className} d="M139.45,67.6c-3.4-8.46-8.55-27.46-11.95-44.06H112.32C116,42.38,123.52,66,131.15,81.62h16.6c7.63-15.6,15.18-39.24,18.83-58.08H151.4C148,40.14,142.85,59.14,139.45,67.6Z" />
            <path className={className} d="M195.53,22.55c-16.75,0-27.12,12-27.12,30s10.37,30,27.12,30,27.05-11.94,27.05-30S212.21,22.55,195.53,22.55Zm0,47.12c-7.71,0-11.94-6.05-11.94-17.09s4.23-17.09,11.94-17.09,11.87,6.06,11.87,17.09S203.17,69.67,195.53,69.67Z" />
            <polygon className={className} points="230.81 81.62 268.14 81.62 268.14 69.17 245.49 69.17 245.49 58.55 264.74 58.55 264.74 46.11 245.49 46.11 245.49 35.99 268.14 35.99 268.14 23.55 230.81 23.55 230.81 81.62" />
            <polygon className={className} points="273.4 35.99 287.75 35.99 287.75 81.62 302.44 81.62 302.44 35.99 316.79 35.99 316.79 23.55 273.4 23.55 273.4 35.99" />
            <path className={className} d="M358.22,50.18A14,14,0,0,0,364,38.64c0-9.62-8.05-15.1-20.91-15.1H322.8V81.62h21.73c13.19,0,21.49-6.31,21.49-17.76C366,58.14,363.53,53.08,358.22,50.18ZM337.07,36h6.14c3.4,0,6.22,1.58,6.22,5.06,0,3.65-1.66,5.64-5.56,5.64h-6.8Zm6.22,33.18h-6.22V57.64h6.22c5.39,0,8.21,1.74,8.21,6.06C351.5,67.43,348.68,69.17,343.29,69.17Z" />
            <path className={className} d="M388,23.54C380.91,38.39,373.2,63,368.39,81.62h15.1c.91-3.57,1.74-7.14,2.65-10.7h18.92c.91,3.56,1.74,7.13,2.65,10.7h15.18C418.08,63,410.28,38.39,403.23,23.54Zm1.41,34.93c2.24-8.21,4.39-15.26,6.22-19.25,1.82,4,4,11,6.22,19.25Z" />
            <polygon className={className} points="443.26 69.17 443.26 23.55 428.58 23.55 428.58 81.62 464.83 81.62 464.83 69.17 443.26 69.17" />
        </PathWrapper>
    )
}

export default LogoZSVoetbal
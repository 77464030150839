import React from 'react';
import { gsap } from "gsap";
import Panel from './Panel';
const { useLayoutEffect, useRef } = React;

function PanelLoop() {

    const tl = useRef();

    useLayoutEffect(() => {
        tl.current = gsap
            .timeline({
                repeat: -1,
                defaults: {
                    ease: "power1.inOut",
                    duration: 2,
                    transformOrigin: "50% 50%"
                }
            })
            .fromTo("#Panel1", { x: 1030, y: 620, scale: 1.5, rotate: "15deg" }, { y: 540, rotate: "5deg" }, 0)
            .to("#Panel1", {  x: 1080, y: 640, rotate: "180deg", scale: 2, duration: 8 }, 2)
            .to("#Panel1", {  x: 1030, opacity: 0 }, 6)
            .to("#Panel1", {  x: 960, y: 1080, rotate: "270deg" }, 8)
            .to("#Panel1", {  x: 1030, y: 620, scale: 1.5, rotate: "375deg", opacity: 1 }, 10)

            .fromTo("#Panel2", { x: 0, y: 540, scale: 2, rotate: "45deg" }, { scale: 2.1, rotate: "50deg" }, 0)
            .to("#Panel2", {  x: 240, y: 640, rotate: "180deg", scale: 3, duration: 6 }, 2)
            .to("#Panel2", {  opacity: 0, duration: 1 }, 5)
            .to("#Panel2", {  x: -1920, y: 960, rotate: "360deg" }, 6)
            .to("#Panel2", {  x: 0, y: 540, scale: 2, rotate: "405deg", opacity: 1, duration: 4 }, 8)

            .fromTo("#Panel3", { x: 1440, y: -300, scale: 2, rotate: "-30deg" }, { x: 2820, y: 640, rotate: "90deg", scale: 2.5, duration: 6 }, 0)
            .to("#Panel3", {  opacity: 0, duration: 1 }, 5)
            .to("#Panel3", {  x: -320, y: -540, rotate: "225deg" , duration: 0.1 }, 6)
            .to("#Panel3", {  x: 1440, y: -300, scale: 2, rotate: "330deg", opacity: 1, duration: 4 }, 8)
    }, []);

    return (
        <>
            <g id="Panel1">
                <Panel delay={2} />
            </g>
            <g id="Panel2">
                <Panel delay={4} />
            </g>
            <g id="Panel3">
                <Panel delay={1} />
            </g>
        </>
    );
}

export default PanelLoop;

import {LogoZS, LogoZSDocu, LogoZSGolf, LogoZSRacing, LogoZSSelect, LogoZSVoetbal} from './Logos'

const LogoLabel = () => {
    return (
        <>
        <rect className="fill-darkorange" x="520" y="890" width="330" height="200" rx="8"/>
        <g id="ZiggoLogo" className="auto-alpha">
            <LogoZS className={"fill-white"} posX={565} posY={930} width={240} />
        </g>
        </>
    )
}

export default LogoLabel
import PathWrapper from "../PathWrapper"

const LogoZS = ({className, width , posX, posY}) => {
    return (
        <PathWrapper
            originalWidth={262}
            desiredWidth={width}
            posX={posX}
            posY={posY}
        >
            <path className={className} d="M23.29,72.89c1.51,3.49,4.06,4.91,8.79,4.91H77.25a12.34,12.34,0,0,0-10.5-11.9H40.54L70.74,37a9,9,0,0,0,2.08-9.77c-1.64-3.57-4.37-5.09-9.16-5.09H26c.16,5,2.64,10.94,9.27,12h21.5L25,64A8.44,8.44,0,0,0,23.29,72.89Z"/>
            <path className={className} d="M88,33V77.43A10.58,10.58,0,0,1,77.46,88h-55A10.58,10.58,0,0,1,11.9,77.44l0-54.92A10.6,10.6,0,0,1,22.46,11.94h55a10.55,10.55,0,0,1,4.11.83A12.14,12.14,0,0,1,85.79,16,6,6,0,0,0,96,11.72,6.21,6.21,0,0,0,94.2,7.49,21.81,21.81,0,0,0,86,1.71,22.18,22.18,0,0,0,77.46,0h-55A22.48,22.48,0,0,0,0,22.45V77.52A22.49,22.49,0,0,0,22.48,100H77.56A22.47,22.47,0,0,0,99.94,77.44V22.12C93.68,22.12,88,27.32,88,33Z"/>
            <path className={className} d="M138.7,17.56V11.07H115.56v7.37h14.05C125.83,26,120.08,33,115,38.83v6.63h24.91V38.09h-14.4A83.64,83.64,0,0,0,138.7,17.56Z"/>
            <rect className={className} x="146.24" y="11.07" width="8.7" height="34.39"/>
            <path className={className} d="M178.31,46.05a23.16,23.16,0,0,0,11.45-2.95V25.22H177.08v7.12h4.82v5.75a15.32,15.32,0,0,1-3.14.29c-5.41,0-8.36-3.29-8.36-9.62,0-7,2.95-10.62,8.85-10.62a20.29,20.29,0,0,1,7.22,1.28l1.86-7.27a26.4,26.4,0,0,0-9.23-1.67c-10.91,0-17.69,7.27-17.69,18.28C161.41,39.17,167.75,46.05,178.31,46.05Z"/>
            <path className={className} d="M212.36,46.05a23.16,23.16,0,0,0,11.45-2.95V25.22H211.13v7.12H216v5.75a15.42,15.42,0,0,1-3.15.29c-5.4,0-8.35-3.29-8.35-9.62,0-7,3-10.62,8.84-10.62a20.35,20.35,0,0,1,7.23,1.28l1.86-7.27a26.44,26.44,0,0,0-9.23-1.67c-10.91,0-17.69,7.27-17.69,18.28C195.46,39.17,201.8,46.05,212.36,46.05Z"/>
            <path className={className} d="M245.7,46.05c9.87,0,16-7.08,16-17.79s-6.14-17.78-16-17.78-16.07,7.08-16.07,17.78S235.78,46.05,245.7,46.05Zm0-27.91c4.52,0,7,3.59,7,10.12s-2.5,10.12-7,10.12-7.08-3.58-7.08-10.12S241.13,18.14,245.7,18.14Z"/>
            <path className={className} d="M128.88,68.15c-3.49-1.28-4.71-1.92-4.71-3.93,0-1.77,1.32-2.56,3.93-2.56a18.5,18.5,0,0,1,7.51,1.58l1.67-7.18a23.8,23.8,0,0,0-9.67-1.91c-8,0-12.24,3.88-12.24,10.36,0,5.85,3.74,8.55,9.24,10.47,4,1.37,5.65,2,5.65,4.37,0,1.77-1.33,2.85-4.47,2.85a25,25,0,0,1-8.89-1.87l-1.58,7.32a27.9,27.9,0,0,0,10.86,2.06c7.91,0,12.87-3.88,12.87-10.41C139.05,72.57,134.73,70.26,128.88,68.15Z"/>
            <path className={className} d="M154.6,54.74H143.11V89.12h8.69V79.05h2.8c8.6,0,14-4.52,14-12.13S163.2,54.74,154.6,54.74Zm-.19,16.95H151.8V62.11h2.61c3.39,0,5.16,1.27,5.16,4.81S157.8,71.69,154.41,71.69Z"/>
            <path className={className} d="M187.52,54.15c-9.93,0-16.07,7.07-16.07,17.78s6.14,17.78,16.07,17.78,16-7.07,16-17.78S197.39,54.15,187.52,54.15Zm0,27.9c-4.57,0-7.08-3.59-7.08-10.12s2.51-10.12,7.08-10.12,7,3.59,7,10.12S192,82.05,187.52,82.05Z"/>
            <path className={className} d="M226.92,74.39c3-1.13,6-3.24,6-9.19,0-6.53-5.2-10.46-13.8-10.46h-11V89.12h8.75V77.19h1.32a3.62,3.62,0,0,1,3.69,2.4l4,9.53h9.23L230.9,79C230,76.79,228.74,75.12,226.92,74.39Zm-8.15-4.52H216.9V62.11h1.87c3.34,0,5.25.83,5.25,3.88C224,68.49,222.45,69.87,218.77,69.87Z"/>
            <polygon className={className} points="236.21 54.74 236.21 62.11 244.71 62.11 244.71 89.12 253.4 89.12 253.4 62.11 261.9 62.11 261.9 54.74 236.21 54.74"/>
        </PathWrapper>
    )
}

export default LogoZS
import React from 'react';
import './App.css';
import NotFound from './pages/NotFound';
import Overlay from './pages/Overlay';
import { BrowserRouter as HashRouter, Routes, Route } from 'react-router-dom';

const App = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/overlay/:id" element={<Overlay channel={'overlay'}/>} />
        <Route path="/preview/:id" element={<Overlay channel={'preview'}/>} />
        <Route path="/dls/:id" element={<Overlay channel={'full-screen'}/>} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </HashRouter>
  );
}

export default App
import PathWrapper from "../PathWrapper"

const IconYoutube = ({className, width , posX, posY}) => {
    return (
        <PathWrapper
            originalWidth={80}
            desiredWidth={width}
            posX={posX}
            posY={posY}
        >
            <path className={className} d="M58.75,15.46a7.64,7.64,0,0,0-5.34-5.37c-4.7-1.32-23.57-1.32-23.57-1.32S11,8.77,6.27,10.09A7.62,7.62,0,0,0,.94,15.46C-.71,25,.15,34.94.94,44.64a7.44,7.44,0,0,0,5.33,5.27c4.7,1.32,23.57,1.32,23.57,1.32s18.87,0,23.57-1.32a7.49,7.49,0,0,0,5.34-5.27A85.6,85.6,0,0,0,58.75,15.46ZM23.67,39.05V21.11l15.8,9Z"/>
        </PathWrapper>
    )
}

export default IconYoutube
import PathWrapper from "../PathWrapper"

const IconFacebook = ({className, width , posX, posY}) => {
    return (
        <PathWrapper
            originalWidth={80}
            desiredWidth={width}
            posX={posX}
            posY={posY}
        >
            <path className={className} d="M30,0a30.19,30.19,0,0,0-4.55,60V39.13H17.73V30.35H25.4v-6.7c0-7.62,4.51-11.82,11.46-11.82a46.39,46.39,0,0,1,6.76.6v7.45H39.76a3.94,3.94,0,0,0-1,0,4.39,4.39,0,0,0-3.95,4.77v5.74h8.37l-1.33,8.74h-7V59.94A30.19,30.19,0,0,0,30,0Z"/>
        </PathWrapper>
    )
}

export default IconFacebook
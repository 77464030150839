import PathWrapper from "../PathWrapper"

const IconInstagram = ({className, width , posX, posY}) => {
    return (
        <PathWrapper
            originalWidth={80}
            desiredWidth={width}
            posX={posX}
            posY={posY}
        >
            <path className={className} d="M30,14.61A15.38,15.38,0,1,0,45.37,30h0a15.34,15.34,0,0,0-15.3-15.39ZM30,40A10,10,0,1,1,40,30,10,10,0,0,1,30,40h0ZM49.61,14A3.6,3.6,0,1,1,46,10.4h0A3.59,3.59,0,0,1,49.61,14ZM59.8,17.63A16.64,16.64,0,0,0,44,.21a15,15,0,0,0-1.64,0c-5-.28-19.76-.28-24.76,0A16.59,16.59,0,0,0,.2,15.92a16.07,16.07,0,0,0,0,1.7c-.27,4.95-.27,19.76,0,24.75A16.64,16.64,0,0,0,16,59.79a15,15,0,0,0,1.64,0c4.95.28,19.76.28,24.76,0A16.63,16.63,0,0,0,59.8,44V42.37c.27-5,.27-19.77,0-24.75ZM53.39,47.68a10.07,10.07,0,0,1-5.65,5.64c-4,1.58-13.32,1.22-17.68,1.22s-13.74.34-17.68-1.22a10.06,10.06,0,0,1-5.64-5.64C5.17,43.72,5.52,34.36,5.52,30S5.18,16.25,6.74,12.31a10.13,10.13,0,0,1,5.64-5.65c4-1.56,13.31-1.2,17.68-1.2s13.75-.35,17.68,1.2a10.12,10.12,0,0,1,5.65,5.65C55,16.28,54.6,25.64,54.6,30S55,43.74,53.39,47.68Z"/>
        </PathWrapper>
    )
}

export default IconInstagram
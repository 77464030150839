import { gsap } from "gsap";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {IconFacebook, IconInstagram, IconYoutube, IconTwitter} from "./Icons";

const Socials = ({ show, setSocialTickerState }) => {
    const graphicRef = useRef(null);
    const timelineRef = useRef(null);
    const contextRef = useRef(null);
    const [currentLabel, setCurrentLabel] = useState('hiddenIn');

    /**
     * Create the timeline
     */
    useLayoutEffect(() => {
        timelineRef.current = gsap.timeline({
            paused: true,
            ease: 'power2.inOut',
            onComplete: () => {setSocialTickerState({show: false, properties: []}); setCurrentLabel('hiddenOut');}
        });
        // Set the context for this timeline
        contextRef.current = gsap.context(() => {

            // Default starting states */
            gsap.set("#SocialsOrangeWhipe", { scaleX: 1 });

            // In- and Out- animations
            timelineRef.current
                .addLabel('hiddenIn')
                .fromTo("#SocialsOrangeWhipe", { x: "-105%" }, { x: "105%" })
                .fromTo("#SocialsMatte", { scaleX: 0 }, { scaleX: 1 }, "<")
                .to("#SocialsList", {y: "+=100", delay: 1.5})
                .to("#SocialsList", {y: "+=100", delay: 1.5})
                .to("#SocialsList", {y: "+=100", delay: 1.5})
                .addLabel('visible')
                .fromTo("#SocialsOrangeWhipe", { x: "-105%" }, { x: "105%" }, ">1.5")
                .to("#SocialsMatte", { x: "+=105%", scaleX: 0 }, "<")
                .addLabel('hiddenOut');
        }, graphicRef);

        // Clean up GSAP when component unmounts
        return () => {
            timelineRef.current.kill();
            contextRef.current.revert();
        };
    }, [setSocialTickerState]);

    /**
     * Listen for events
     */
    useEffect(() => {
        if (show) {
            if (currentLabel === 'hiddenIn' || currentLabel === 'hiddenOut') {
                timelineRef.current.tweenFromTo('hiddenIn', 'hiddenOut');
                setCurrentLabel('visible');
            }
        } else {
            if (currentLabel === 'visible') {
                timelineRef.current.tweenFromTo('visible', 'hiddenOut');
                setCurrentLabel('hiddenOut');
            }
        }

    }, [show, currentLabel])

    return (
        <g ref={graphicRef}>
            <mask id="SocialsMask">
                <rect id="SocialsMatte" className="fill-white" x="140" y="892" width="380" height="80" rx="12" />
            </mask>
            <g mask="url(#SocialsMask)" id="Socials">
                <rect className="fill-orange" x="140" y="892" width="80" height="80"/>
                <rect className="fill-black" x="220" y="892" width="300" height="80"/>

                <g id="SocialsList">
                    <IconTwitter className={"fill-white"} width={80} posX={150} posY={902} />
                    <text id="TextTwitter" className="font-diodrum text-lg fill-white" transform="translate(245 945)">#ZiggoSport</text>

                    <IconYoutube className={"fill-white"} width={80} posX={150} posY={802} />
                    <text id="TextTwitter" className="font-diodrum text-lg fill-white" transform="translate(245 845)">Ziggo Sport</text>

                    <IconFacebook className={"fill-white"} width={80} posX={150} posY={702} />
                    <text id="TextTwitter" className="font-diodrum text-lg fill-white" transform="translate(245 745)">@ZiggoSport</text>

                    <IconInstagram className={"fill-white"} width={80} posX={150} posY={602} />
                    <text id="TextTwitter" className="font-diodrum text-lg fill-white" transform="translate(245 645)">@ZiggoSport</text>
                </g>

                <rect id="SocialsOrangeWhipe" className="fill-orange"x="140" y="892" width="380" height="80" rx="12" />
            </g>
        </g>
    )
}

export default Socials
import PathWrapper from "../PathWrapper"

const LogoZSSelect = ({ className, width, posX, posY }) => {
    return (
        <PathWrapper
            originalWidth={385}
            desiredWidth={width}
            posX={posX}
            posY={posY}
        >
            <path className={className} d="M23.29,72.89c1.51,3.49,4.06,4.91,8.79,4.91H77.25a12.34,12.34,0,0,0-10.5-11.9H40.54L70.74,37a9,9,0,0,0,2.08-9.77c-1.64-3.57-4.37-5.09-9.16-5.09H26c.16,5,2.64,10.94,9.27,12h21.5L25,64A8.44,8.44,0,0,0,23.29,72.89Z" />
            <path className={className} d="M88,33V77.43A10.58,10.58,0,0,1,77.46,88h-55A10.58,10.58,0,0,1,11.9,77.44l0-54.92A10.6,10.6,0,0,1,22.46,11.94h55a10.55,10.55,0,0,1,4.11.83A12.14,12.14,0,0,1,85.79,16,6,6,0,0,0,96,11.72,6.21,6.21,0,0,0,94.2,7.49,21.81,21.81,0,0,0,86,1.71,22.18,22.18,0,0,0,77.46,0h-55A22.48,22.48,0,0,0,0,22.45V77.52A22.49,22.49,0,0,0,22.48,100H77.56A22.47,22.47,0,0,0,99.94,77.44V22.12C93.68,22.12,88,27.32,88,33Z" />
            <path className={className} d="M136.88,46.19c-5.89-2.15-8-3.23-8-6.63,0-3,2.24-4.32,6.64-4.32a31.34,31.34,0,0,1,12.69,2.66l2.82-12.12a40.25,40.25,0,0,0-16.34-3.23c-13.52,0-20.66,6.55-20.66,17.5,0,9.88,6.31,14.44,15.6,17.68,6.72,2.32,9.54,3.4,9.54,7.38,0,3-2.24,4.81-7.55,4.81a42.44,42.44,0,0,1-15-3.15L114,79.13c4.15,1.91,11,3.48,18.33,3.48,13.36,0,21.74-6.55,21.74-17.58C154.05,53.66,146.75,49.76,136.88,46.19Z" />
            <polygon className={className} points="161.32 81.62 198.65 81.62 198.65 69.17 176 69.17 176 58.55 195.25 58.55 195.25 46.11 176 46.11 176 35.99 198.65 35.99 198.65 23.55 161.32 23.55 161.32 81.62" />
            <polygon className={className} points="222.62 23.55 207.94 23.55 207.94 81.62 244.19 81.62 244.19 69.17 222.62 69.17 222.62 23.55" />
            <polygon className={className} points="250.57 81.62 287.91 81.62 287.91 69.17 265.26 69.17 265.26 58.55 284.51 58.55 284.51 46.11 265.26 46.11 265.26 35.99 287.91 35.99 287.91 23.55 250.57 23.55 250.57 81.62" />
            <path className={className} d="M322.19,69.67c-9.38,0-13.27-5.72-13.27-16.92s4-17.26,13.6-17.26c3.9,0,7.55,1.41,10.54,2.49l3.23-12.44a39.53,39.53,0,0,0-13.93-3c-18.67,0-28.63,12.11-28.63,30.28,0,17.92,9.88,29.78,27.8,29.78A33.46,33.46,0,0,0,336.87,79l-2.82-12.61A23.66,23.66,0,0,1,322.19,69.67Z" />
            <polygon className={className} points="341.26 23.55 341.26 35.99 355.62 35.99 355.62 81.62 370.3 81.62 370.3 35.99 384.65 35.99 384.65 23.55 341.26 23.55" />
        </PathWrapper>
    )
}

export default LogoZSSelect
import PathWrapper from "../PathWrapper"

const LogoZSRacing = ({ className, width, posX, posY }) => {
    return (
        <PathWrapper
            originalWidth={403}
            desiredWidth={width}
            posX={posX}
            posY={posY}
        >
            <path className={className} d="M23.29,72.89c1.51,3.49,4.06,4.91,8.79,4.91H77.25a12.34,12.34,0,0,0-10.5-11.9H40.54L70.74,37a9,9,0,0,0,2.08-9.77c-1.64-3.57-4.37-5.09-9.16-5.09H26c.16,5,2.64,10.94,9.27,12h21.5L25,64A8.44,8.44,0,0,0,23.29,72.89Z" />
            <path className={className} d="M88,33V77.43A10.58,10.58,0,0,1,77.46,88h-55A10.58,10.58,0,0,1,11.9,77.44l0-54.92A10.6,10.6,0,0,1,22.46,11.94h55a10.55,10.55,0,0,1,4.11.83A12.14,12.14,0,0,1,85.79,16,6,6,0,0,0,96,11.72,6.21,6.21,0,0,0,94.2,7.49,21.81,21.81,0,0,0,86,1.71,22.18,22.18,0,0,0,77.46,0h-55A22.48,22.48,0,0,0,0,22.45V77.52A22.49,22.49,0,0,0,22.48,100H77.56A22.47,22.47,0,0,0,99.94,77.44V22.12C93.68,22.12,88,27.32,88,33Z" />
            <path className={className} d="M148.16,56.73c5.06-1.91,10.21-5.48,10.21-15.51,0-11-8.8-17.68-23.32-17.68H116.47V81.62h14.77V61.46h2.24a6.11,6.11,0,0,1,6.22,4.06l6.8,16.1h15.6l-7.22-17.09C153.3,60.8,151.23,58,148.16,56.73ZM134.39,49.1h-3.15V36h3.15c5.64,0,8.88,1.41,8.88,6.55C143.27,46.77,140.61,49.1,134.39,49.1Z" />
            <path className={className} d="M185.79,23.54C178.74,38.39,171,63,166.21,81.62h15.1c.92-3.57,1.75-7.14,2.66-10.7h18.91c.92,3.56,1.75,7.13,2.66,10.7h15.18C215.91,63,208.11,38.39,201.06,23.54Zm1.41,34.93c2.24-8.21,4.4-15.26,6.23-19.25,1.82,4,4,11,6.22,19.25Z" />
            <path className={className} d="M250.76,69.67c-9.38,0-13.28-5.72-13.28-16.92s4-17.26,13.61-17.26c3.9,0,7.55,1.41,10.53,2.49l3.24-12.44a39.53,39.53,0,0,0-13.94-3c-18.66,0-28.62,12.11-28.62,30.28,0,17.92,9.87,29.78,27.79,29.78A33.51,33.51,0,0,0,265.44,79l-2.82-12.61A23.69,23.69,0,0,1,250.76,69.67Z" />
            <rect className={className} x="272.47" y="23.54" width="14.68" height="58.07" />
            <path className={className} d="M333.38,54.16a65.16,65.16,0,0,0,.33,7,308.07,308.07,0,0,1-21.9-37.59H297.62V81.62h13.44V58.47c0-3.4,0-6.8-.33-10.2,7.88,14.35,14.85,23.81,21.82,33.35h14.27V23.54H333.38Z" />
            <path className={className} d="M381.34,47.44v12h8.13v9.7a25.69,25.69,0,0,1-5.31.5c-9.13,0-14.11-5.56-14.11-16.26,0-11.78,5-17.92,14.94-17.92a34,34,0,0,1,12.19,2.16l3.16-12.28c-4.9-2.07-11.62-2.82-15.6-2.82-18.42,0-29.87,12.28-29.87,30.86,0,17.59,10.7,29.2,28.54,29.2a39.13,39.13,0,0,0,19.33-5V47.44Z" />
        </PathWrapper>
    )
}

export default LogoZSRacing
import PathWrapper from "../PathWrapper"

const LogoZSGolf = ({ className, width, posX, posY }) => {
    return (
        <PathWrapper
            originalWidth={311}
            desiredWidth={width}
            posX={posX}
            posY={posY}
        >
            <path className={className} d="M23.29,72.89c1.51,3.49,4.06,4.91,8.79,4.91H77.25a12.34,12.34,0,0,0-10.5-11.9H40.54L70.74,37a9,9,0,0,0,2.08-9.77c-1.64-3.57-4.37-5.09-9.16-5.09H26c.16,5,2.64,10.94,9.27,12h21.5L25,64A8.44,8.44,0,0,0,23.29,72.89Z" />
            <path className={className} d="M88,33V77.43A10.58,10.58,0,0,1,77.46,88h-55A10.58,10.58,0,0,1,11.9,77.44l0-54.92A10.6,10.6,0,0,1,22.46,11.94h55a10.55,10.55,0,0,1,4.11.83A12.14,12.14,0,0,1,85.79,16,6,6,0,0,0,96,11.72,6.21,6.21,0,0,0,94.2,7.49,21.81,21.81,0,0,0,86,1.71,22.18,22.18,0,0,0,77.46,0h-55A22.48,22.48,0,0,0,0,22.45V77.52A22.49,22.49,0,0,0,22.48,100H77.56A22.47,22.47,0,0,0,99.94,77.44V22.12C93.68,22.12,88,27.32,88,33Z" />
            <path className={className} d="M140.86,59.47H149v9.7a25.69,25.69,0,0,1-5.31.5c-9.13,0-14.1-5.56-14.1-16.26,0-11.78,5-17.92,14.93-17.92a34.09,34.09,0,0,1,12.2,2.16l3.15-12.28c-4.9-2.07-11.62-2.82-15.6-2.82-18.42,0-29.87,12.28-29.87,30.86,0,17.59,10.71,29.2,28.54,29.2a39.1,39.1,0,0,0,19.33-5V47.44h-21.4Z" />
            <path className={className} d="M196.33,22.55c-16.76,0-27.13,12-27.13,30s10.37,30,27.13,30,27-11.94,27-30S213,22.55,196.33,22.55Zm0,47.12c-7.71,0-11.94-6.05-11.94-17.09s4.23-17.09,11.94-17.09,11.87,6.06,11.87,17.09S204,69.67,196.33,69.67Z" />
            <polygon className={className} points="246.29 23.55 231.6 23.55 231.6 81.62 267.86 81.62 267.86 69.17 246.29 69.17 246.29 23.55" />
            <polygon className={className} points="310.74 35.99 310.74 23.55 274.24 23.55 274.24 81.62 288.92 81.62 288.92 61.13 307.34 61.13 307.34 48.68 288.92 48.68 288.92 35.99 310.74 35.99" />
        </PathWrapper>
    )
}

export default LogoZSGolf
import PathWrapper from "../PathWrapper"

const LogoZSDocu = ({ className, width, posX, posY }) => {
    return (
        <PathWrapper
            originalWidth={328}
            desiredWidth={width}
            posX={posX}
            posY={posY}
        >
            <path className={className} d="M23.29,72.89c1.51,3.49,4.06,4.91,8.79,4.91H77.25a12.34,12.34,0,0,0-10.5-11.9H40.54L70.74,37a9,9,0,0,0,2.08-9.77c-1.64-3.57-4.37-5.09-9.16-5.09H26c.16,5,2.64,10.94,9.27,12h21.5L25,64A8.44,8.44,0,0,0,23.29,72.89Z" />
            <path className={className} d="M88,33V77.43A10.58,10.58,0,0,1,77.46,88h-55A10.58,10.58,0,0,1,11.9,77.44l0-54.92A10.6,10.6,0,0,1,22.46,11.94h55a10.55,10.55,0,0,1,4.11.83A12.14,12.14,0,0,1,85.79,16,6,6,0,0,0,96,11.72,6.21,6.21,0,0,0,94.2,7.49,21.81,21.81,0,0,0,86,1.71,22.18,22.18,0,0,0,77.46,0h-55A22.48,22.48,0,0,0,0,22.45V77.52A22.49,22.49,0,0,0,22.48,100H77.56A22.47,22.47,0,0,0,99.94,77.44V22.12C93.68,22.12,88,27.32,88,33Z" />
            <path className={className} d="M137.38,23.54H116.47V81.62h20.91c17,0,29.12-10.79,29.12-29.29C166.5,34.16,154.38,23.54,137.38,23.54ZM137,69.17h-5.81V36H137c9.62,0,14.35,5.06,14.35,16.34C151.31,64,146.58,69.17,137,69.17Z" />
            <path className={className} d="M199.12,22.55c-16.76,0-27.13,12-27.13,30s10.37,30,27.13,30,27-11.94,27-30S215.79,22.55,199.12,22.55Zm0,47.12c-7.72,0-12-6.05-12-17.09s4.23-17.09,12-17.09S211,41.55,211,52.58,206.75,69.67,199.12,69.67Z" />
            <path className={className} d="M260.31,69.67C250.93,69.67,247,64,247,52.75s4-17.26,13.61-17.26c3.9,0,7.55,1.41,10.53,2.49l3.24-12.44a39.53,39.53,0,0,0-13.94-3c-18.66,0-28.62,12.11-28.62,30.28,0,17.92,9.87,29.78,27.79,29.78A33.51,33.51,0,0,0,275,79l-2.82-12.61A23.69,23.69,0,0,1,260.31,69.67Z" />
            <path className={className} d="M313.85,23.54v33.6c0,8-3,12.53-9.21,12.53s-9.21-4.56-9.21-12.53V23.54H280.75V58.22c0,15.27,8.46,24.39,23.89,24.39s23.9-9.12,23.9-24.39V23.54Z" />
        </PathWrapper>
    )
}

export default LogoZSDocu
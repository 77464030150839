import Pusher from 'pusher-js';
import PanelLoop from "./PanelLoop";
import { useEffect, useState } from 'react';
import Program from "./Program";
import Results from "./Results";
import FullScreenImage from './FullScreenImage';

const updateState = (setStateFunc, data) => {
    if(data.show) {
        setStateFunc((prevState) => ({
            ...prevState,
            ['properties']: data.properties
        }));
    }
    setStateFunc((prevState) => ({
        ...prevState,
        ['show']: data.show
    }));
};

const FullScreenCanvas = ({channelName}) => {

    // const handleShowResults = () => {
    //     if(showProgram) {
    //         setShowProgram(false)

    //         setTimeout(function(){
    //             setShowResults(true)
    //         }, 500);
    //     } else {
    //         setShowResults(true)
    //     }
    // }

    /**
     * Full screen image
     */
    const [fullScreenImageState, setFullScreenImageState] = useState([{
        show: false,
        properties: []
    }]);

    /**
     * Full screen result
     */
    const [fullScreenResultState, setFullScreenResultState] = useState([{
        show: false,
        properties: []
    }]);

    useEffect(() => {
        Pusher.logToConsole = true;

        const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
            cluster: 'eu'
        });

        // Subscribe to the channel
        const channel = pusher.subscribe(channelName);
        
        // Bind to channel websocket events
		channel.bind('FullScreenImage', function(data) {
		    updateState(setFullScreenImageState, data)
		})

        channel.bind('FullScreenResults', function(data) {
		    updateState(setFullScreenResultState, data)
		})
		
		return (() => {
			pusher.unsubscribe(channelName)
		})
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
            <rect className="fill-black" x="0" y="0" width="1920" height="1080"/>
            <PanelLoop />
            <Program show={false} />
            <Results show={fullScreenResultState.show} properties={fullScreenResultState.properties} />
        </svg>

        <div id="FullScreenImage">
            {fullScreenImageState.show &&
            <FullScreenImage show={fullScreenImageState.show} src={fullScreenImageState?.properties?.url || ""} alt="TEST" />
            }
        </div>
        </>
    )
}

export default FullScreenCanvas
import { gsap } from "gsap";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {LogoZS, LogoZSDocu, LogoZSGolf, LogoZSRacing, LogoZSSelect, LogoZSVoetbal} from './Logos'
import LogoLabel from "./LogoLabel";

const SwitchLogos = ({name, posX, posY}) => {
    if(name === "ZSDocu") {
        return <LogoZSDocu className="fill-white" posX={posX} posY={posY} width={123} />
    } else if (name === "ZSGolf") {
        return <LogoZSGolf className="fill-white" posX={posX} posY={posY} width={119} />
    } else if (name === "ZSRacing") {
        return <LogoZSRacing className="fill-white" posX={posX} posY={posY} width={154} />
    } else if (name === "ZSSelect") {
        return <LogoZSSelect className="fill-white" posX={posX} posY={posY} width={147} />
    } else if (name === "ZSVoetbal") {
        return <LogoZSVoetbal className="fill-white" posX={posX} posY={posY} width={178} />
    } else {
        return <LogoZS className="fill-white" posX={posX} posY={posY} width={100} />
    }
}

const Program = ({ show }) => {
    const graphicRef = useRef(null);
    const timelineRef = useRef(null);
    const contextRef = useRef(null);

    const [programState, setProgramState] = useState({
        title: "Het programma van Zaterdag",
        program: [
            ["test","20:00", "ZS"],
            ["test","20:00", "ZSDocu"],
            ["test","20:00", "ZSVoetbal"],
            ["test","20:00", "ZSRacing"],
            ["test","20:00", "ZSGolf"],
            ["test","20:00", "ZSSelect"]
        ]
    })

    const [currentLabel, setCurrentLabel] = useState('hiddenIn');

    /**
     * Create the timeline
     */
    useLayoutEffect(() => {
        timelineRef.current = gsap.timeline({
            paused: true,
            ease: 'power2.inOut',
            duration: 1
        });
        // Set the context for this timeline
        contextRef.current = gsap.context(() => {

            // Default starting states */
            gsap.set("#Program", { autoAlpha: 0 });

            // In- and Out- animations
            timelineRef.current
                .addLabel('hiddenIn')
                .to("#Program", { autoAlpha: 1 })
                .to("#ProgramMaskOver", {height : 0})
                .addLabel('visible')
                .to("#ProgramWhipeMatte", { scaleX: 0 })
                .addLabel('hiddenOut');
        }, graphicRef);

        // Clean up GSAP when component unmounts
        return () => {
            timelineRef.current.kill();
            contextRef.current.revert();
        };
    }, []);

    /**
     * Listen for events
     */
    useEffect(() => {
        if (show) {
            if (currentLabel === 'hiddenIn' || currentLabel === 'hiddenOut') {
                timelineRef.current.tweenFromTo('hiddenIn', 'visible');
                setCurrentLabel('visible');
            }
        } else {
            if (currentLabel === 'visible') {
                timelineRef.current.tweenFromTo('visible', 'hiddenOut');
                setCurrentLabel('hiddenOut');
            }
        }

    }, [show, currentLabel])

    return (
        <g ref={graphicRef}>
            <mask id="ProgramMask">
                <rect id="ProgramMaskMatte" className="fill-white" x="210" y="165" width="1500" height={programState.program.length * 70 - 8} rx="8"/>
            </mask>

            <mask id="ProgramTimeMask">
                <rect id="ProgramTimeMatte" className="fill-white" x="0" y="0" width="356" height="1080"/>
            </mask>
            
            <mask id="ProgramWhipeMask">
                <rect id="ProgramWhipeMatte" className="fill-white" x="0" y="0" width="1920" height="1080"/>
            </mask>

            <g mask="url(#ProgramWhipeMask)" id="Program">
                <text className="font-diodrum text-2xl fill-orange auto-alpha" transform="translate(520 124)">
                    {programState.title}
                </text>

                <g mask="url(#ProgramMask)">   
                    {programState.program.map((row, index) => (
                        <g key={index}>
                            <rect className="fill-black fill-opacity-65" x="210" y={index * 70 + 165} width="1500" height="62" rx="8"/>
                            <rect mask="url(#ProgramTimeMask)" className="fill-orange" x="210" y={index * 70 + 165} width="156" height="62" rx="8"/>
                            <text className="fill-white font-diodrum text-md" transform={`translate(380 ${index * 70 + 210})`}>{row[0]}</text>
                            <text className="fill-black font-diodrum text-md" transform={`translate(283 ${index * 70 + 210})`} textAnchor="middle">{row[1]}</text>
                            <line className="stroke-gray" strokeWidth="2" x1="1480" y1={index * 70 + 165} x2="1480" y2={index * 70 + 227}/>

                            <SwitchLogos name={row[2]} posX={1495} posY={index * 70 + 176} />
                        </g>
                    ))}
                </g>

                <rect id="ProgramMaskOver" className="fill-darkorange" x="210" y="165" width="1500" height={programState.program.length * 70 - 8} rx="8"/>

                <LogoLabel />
            </g>
        </g>
    )
}

export default Program
/**
 * Calc text width
 *
 * @returns integer
 */

export function calcTextWidth(text, font, size) {
    let d = document.createElement('span');
    let t = document.createTextNode(text);

    d.style.cssText = "font-family:"+font+";font-size:"+size+";white-space:nowrap;visibility:hidden;text-transform: uppercase;";
    d.appendChild(t);

    document.body.appendChild(d);

    let w = d.offsetWidth + 120;

    document.body.removeChild(d);
    return w;
}

/**
 * Split array by formation
 */

export function splitArrayByFormation(playersArray, formation) {
    const counts = formation.split("-").map(Number);
  
    const splitArray = (arr, counts) => {
      const result = [];
      let currentIndex = 0;
  
      for (const count of counts) {
        const group = arr.slice(currentIndex, currentIndex + count);
        result.push(group);
        currentIndex += count;
      }
  
      return result;
    };
  
    const splittedArrays = splitArray(playersArray, counts);
    const leftoverArray = playersArray.slice(splittedArrays.flat().length);
  
    return { splittedArrays, leftoverArray };
  }
import { useEffect, useState } from "react";
import Pusher from 'pusher-js';
import Countdown from "./Countdown"
import LowerThird from "./LowerThird";
import Reference from "./Reference";
import Socials from "./Socials";
import LineupTicker from "./LineupTicker";

const updateState = (setStateFunc, data) => {
    if(data.show) {
        setStateFunc((prevState) => ({
            ...prevState,
            ['properties']: data.properties
        }));
    }
    setStateFunc((prevState) => ({
        ...prevState,
        ['show']: data.show
    }));
};

const GraphicsCanvas = ({ channelName }) => {
    /**
     * Lower Third
     */
    const [lowerThirdState, setLowerThirdState] = useState([{
        show: false,
        properties: []
    }]);

    /**
     * Social Ticker
     */
    const [socialTickerState, setSocialTickerState] = useState([{
        show: false,
        properties: []
    }]);

    /**
     * Match Countdown
     */
    const [matchCountdownState, setMatchCountdownState] = useState([{
        show: false,
        properties: []
    }]);

    /**
     * Channel Reference
     */
    const [channelReferenceState, setChannelReferenceState] = useState([{
        show: false,
        properties: []
    }]);

    /**
     * Lineup Ticker
     */
    const [lineupTickerState, setLineupTickerState] = useState([{
        show: false,
        properties: []
    }]);

    useEffect(() => {
        Pusher.logToConsole = true;

        const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
            cluster: 'eu'
        });

        // Subscribe to the channel
        const channel = pusher.subscribe(channelName);

        // Bind to channel websocket events
        channel.bind('LowerThird', function (data) {
            updateState(setLowerThirdState, data)
        })

        channel.bind('LowerThirdXL', function (data) {
            updateState(setLowerThirdState, data)
        })

        channel.bind('SocialsTicker', function (data) {
            updateState(setSocialTickerState, data)
        })

        channel.bind('MatchCountdown', function (data) {
            updateState(setMatchCountdownState, data)
        })

        channel.bind('ChannelReference', function (data) {
            updateState(setChannelReferenceState, data)
        })
        
        channel.bind('LineupTicker', function (data) {
            updateState(setLineupTickerState, data)
        })


        return (() => {
            pusher.unsubscribe(channelName)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
                <Countdown show={matchCountdownState.show} properties={matchCountdownState.properties} />
                <Reference show={channelReferenceState.show} properties={channelReferenceState.properties} />
                <LowerThird show={lowerThirdState.show} properties={lowerThirdState.properties} />
                <Socials show={socialTickerState.show} setSocialTickerState={setSocialTickerState} />
                <LineupTicker show={lineupTickerState.show} properties={lineupTickerState.properties} channelName={channelName} />
            </svg>
        </>
    )
}

export default GraphicsCanvas
import { gsap } from "gsap";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { calcTextWidth } from "../helpers";

const LowerThird = ({ show, properties }) => {
    const graphicRef = useRef(null);
    const timelineRef = useRef(null);
    const contextRef = useRef(null);
    const [sizes, setSizes] = useState({ top: 400, bottom: 400 });
    const [currentLabel, setCurrentLabel] = useState('hiddenIn');

    /**
     * Create the timeline
     */
    useLayoutEffect(() => {
        timelineRef.current = gsap.timeline({
            paused: true,
            ease: 'power2.inOut',
            duration: 1
        });
        // Set the context for this timeline
        contextRef.current = gsap.context(() => {

            // Default starting states */
            gsap.set(["#OrangeWhipe", "#OrangeWhipeBottom"], { scaleX: 1 });
            gsap.set("#BackgroundBottom", { scaleY: 0, transformOrigin: "bottom" });

            // In- and Out- animations
            timelineRef.current
                .addLabel('hiddenIn')
                .fromTo(["#OrangeWhipe", "#OrangeWhipeBottom"], { x: "-105%" }, { x: "105%", duration: 0.3 })
                .fromTo("#LowerThirdMatte", { scaleX: 0 }, { scaleX: 1, duration: 0.3 }, "<")
                .fromTo("#BackgroundTop", { scaleX: 0 }, { scaleX: 1, duration: 0.3 }, "<")
                .to(["#LowerThirdTop", "#LowerThirdMatte", "#OrangeWhipe"], {y: -70, duration: 0.3}, ">0.2")
                .to("#BackgroundBottom", {scaleY: 1, duration: 0.3}, "<")
                .addLabel('visible')
                .fromTo("#OrangeWhipe", { x: "-105%" }, { x: "105%" })
                .fromTo("#OrangeWhipeBottom", { x: "-105%" }, { x: "105%" }, ">-0.5")
                .fromTo("#LowerThirdMatte", { scaleX: 1 }, { scaleX: 0, x: "105%" }, ">-0.5")
                .addLabel('hiddenOut');
        }, graphicRef);

        // Clean up GSAP when component unmounts
        return () => {
            timelineRef.current.kill();
            contextRef.current.revert();
        };
    }, []);

    /**
     * Listen for events
     */
    useEffect(() => {
        if (show) {
            if (currentLabel === 'hiddenIn' || currentLabel === 'hiddenOut') {
                timelineRef.current.tweenFromTo('hiddenIn', 'visible');
                setCurrentLabel('visible');
            }
        } else {
            if (currentLabel === 'visible') {
                timelineRef.current.tweenFromTo('visible', 'hiddenOut');
                setCurrentLabel('hiddenOut');
            }
        }

    }, [show, currentLabel])

    /**
     * Calculate width when properties change
     */
    useEffect(() => {
        if(properties) {
            let topSize = calcTextWidth(properties.title, 'Diodrum-Semibold', "42px");
            let bottomSize = calcTextWidth(properties.subtitle, 'Diodrum-Semibold', "36px");

            let theSize = topSize >= bottomSize ? topSize : bottomSize;

            setSizes({
                top: theSize,
                bottom: theSize
            });
        }
    }, [properties])

    return (
        <g id="LowerThird" ref={graphicRef}>
            <mask id="LowerThirdMask">
                <rect id="LowerThirdMatte" className="fill-white" x="140" y="892" width={sizes.top} height="150" rx="8" />
            </mask>

            <g mask="url(#LowerThirdMask)">
                <g id="LowerThirdBottom">
                    <rect id="BackgroundBottom" className="fill-orange" x="140" y="902" width={sizes.bottom} height="70" />
                    <text class="font-diodrum text-md fill-black" transform="translate(160 945)" textAnchor="start">{properties && properties.subtitle}</text>
                </g>

                <g id="LowerThirdTop">
                    <rect id="BackgroundTop" className="fill-black" x="140" y="892" width={sizes.top} height="80" />
                    <text class="font-diodrum text-lg fill-white" transform="translate(160 945)" textAnchor="start">{properties && properties.title}</text>
                </g>

                <rect id="OrangeWhipe" className="fill-orange" x="140" y="892" width={sizes.top} height="80" />
                <rect id="OrangeWhipeBottom" className="fill-orange" x="140" y="892" width={sizes.top} height="80" />
            </g>
        </g>
    )
}

export default LowerThird
import { gsap } from "gsap";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

const Logo = ({ url, posX, posY }) => {
    return (
        <foreignObject width="240" height="240" x={posX} y={posY}>
            <img src={url} style={{ width: '100%', height: '100%' }} alt="" />
        </foreignObject>
    )
}

const calculateTimeRemaining = (time) => {
    const targetTime = Date.parse(`23 May 2023 ${time}:00`);

    const now = new Date().getTime();
    const timeDiff = targetTime - now;

    let minutes = 0;
    let seconds = 0;

    if (timeDiff > 0) {
        minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
    }

    return { minutes, seconds };
};

const Countdown = ({ show, properties }) => {
    const graphicRef = useRef(null);
    const timelineRef = useRef(null);
    const contextRef = useRef(null);
    const [currentLabel, setCurrentLabel] = useState('hiddenIn');
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
    
    /**
     * Create the timeline
     */
    useLayoutEffect(() => {
        timelineRef.current = gsap.timeline({
            paused: true,
            ease: 'power2.inOut',
            duration: 1
        });

        const timer = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining(properties?.kickoff || "04:30"));
        }, 1000);

        // Set the context for this timeline
        contextRef.current = gsap.context(() => {

            // Default starting states */
            gsap.set("#CountdownMatte", { x: "-50%" });
            gsap.set(["#LogoBackground", "#LogoBackgroundMatte"], { transformOrigin: 'center center'});

            // In- and Out- animations
            timelineRef.current
                .addLabel('hiddenIn')
                .to("#CountdownMatte", {x: "+=100%", duration: 1})
                .to("#LogoBackgroundMatte", { rotate: 120, duration: 1, y: "-=520" }, "<")
                .to("#Logos foreignObject", { y: "-=50", stagger: 0.2}, ">-0.5")
                .addLabel('visible')
                .to("#LogoBackgroundMatte", { rotate: 180, x: "+=300", y: "+=300" })
                .to("#CountdownMatte", {x: "+=100%"}, ">-0.2")
                .addLabel('hiddenOut');
        }, graphicRef);

        // Clean up GSAP when component unmounts
        return () => {
            timelineRef.current.kill();
            contextRef.current.revert();
            clearInterval(timer);
        };
    }, []);

    /**
     * Listen for events
     */
    useEffect(() => {
        if (show) {
            if (currentLabel === 'hiddenIn' || currentLabel === 'hiddenOut') {
                timelineRef.current.tweenFromTo('hiddenIn', 'visible');
                setCurrentLabel('visible');
            }
        } else {
            if (currentLabel === 'visible') {
                timelineRef.current.tweenFromTo('visible', 'hiddenOut');
                setCurrentLabel('hiddenOut');
            }
        }

    }, [show, currentLabel])

    return (
        <g ref={graphicRef}>
            <mask id="CountdownMask">
                <rect id="CountdownMatte" className="fill-white" x="0" y="540" width="1920" height="540" transform="skewX(-30)" /> 
            </mask>
            <mask id="BackgroundMask">
                <rect id="BackgroundMatte" className="fill-white" x="1068" y="672" width="756" height="300" rx="16" />
            </mask>
            <mask id="LogoBackgroundMask">
                <rect id="LogoBackgroundMatte" className="fill-white" x="1550" y="1100" width="500" height="500" rx="30" />
            </mask>

            <g mask="url(#CountdownMask)" id="Countdown">
                <rect id="Background" className="fill-orange" x="1068" y="772" width="756" height="200" rx="16" />
                <g mask="url(#LogoBackgroundMask)">
                    <rect id="LogoBackground" className="fill-black" x="1068" y="772" width="756" height="200" rx="16" />
                </g>
                <text id="Top" className="font-diodrum text-lg fill-white" transform="translate(1098.16 839.91)">{properties && properties.home}</text>
                <text id="Bottom" className="font-diodrum text-lg fill-white" transform="translate(1098.16 895.91)">{properties && properties.away}</text>
                <text id="Text" className="font-diodrum text-sm fill-black" transform="translate(1098.16 942.57)">
                    Aftrap over:
                </text>
                <text id="Time" className="font-diodrum-mono text-sm fill-black" transform="translate(1261.03 942.57)">
                    {timeRemaining.minutes < 10 ? '0' + timeRemaining.minutes : timeRemaining.minutes}:
                    {timeRemaining.seconds < 10 ? '0' + timeRemaining.seconds : timeRemaining.seconds}
                </text>

                <g mask="url(#BackgroundMask)">
                    <g id="Logos" mask="url(#LogoBackgroundMask)">
                        <Logo url={properties?.home_logo || ''} posX={1624} posY={790} />
                        <Logo url={properties?.away_logo || ''} posX={1501} posY={790} />
                    </g>
                </g>
            </g>           
        </g>
    )
}

export default Countdown